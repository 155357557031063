.barra{
    background-color: var(--color_titulos);
    padding: 50px;
  }
  
  .barra__inferior p{
    margin: 15px;
    color: var(--color_letras);
    font-size: 20px;
    margin-left: 20px;
    flex-wrap: wrap;
  
  }

  .barra img{
    float: left;
    width: 20%;
    padding: 15px;
    
  }

  @media (max-width: 700px){
    .barra img{
      display: none;
    }
  }

  @media (max-width: 700px){
    .barra__inferior p{
        
        padding: 0px;
        margin: 5px;
        text-align: left;
        
        
    }
  }