:root{
  --color_letras: rgb(248, 248, 248);
}

:root{
  --color_titulos: rgb(243, 189, 12);
}

body{
  background-color: black;
  display: flex;
  flex-direction: column;
}

@media (min-width:1000px){
  .Home{
    display: flex;
    flex-direction: column;
  }
}

.texto__descripcion{
  color: white;
}









/* .texto{
  justify-content: space-around;
  font-family: sans-serif;
  color: red;
  text-align: center;
  font-family: larger;
} */

/* .texto2{
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(187, 184, 184);
  text-align: center;
  font-family: medium;
  font-style: normal;
} */


/* code {
  font-family:
   source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .logo{
  
} */

/* .portada img{
  width: 100%;
  height: 555px;
  
} */



/* .transporte h1{
  color: rgb(51, 51, 145);
} */

/* .transporte p{
  font-size: 20px;
  margin: 20px;
} */

/* .transporte img{
    height: 50%;
    width: 50%;
    float: left;
} */

/* .transporte{
  float: left;
  text-align: center;
  
} */

/* .barra img{
  float: left;
  width: 20%;
  padding: 15px;
  
} */

/* .barra{
  background-color: black;
  padding: 50px;
} */

/* .barra2 p{
  margin: 15px;
  color: white;
  font-size: 20px;
  margin-left: 20px;
  display: flex;
  
} */

/* .fondo{
  background-image: url(./components/Turismo/Imagenes/fondo.jpg);
  background-color: red;
} */