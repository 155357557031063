.confirmar{
    
}

.linearoja{
    margin-left: 70px;
    margin-right: 70px;
    color: var(--color_letras);
    
}

.reserva h2{
    float: right;
    
    margin-right: 200px;
    padding: 0px;
    color: var(--color_titulos);
    
}

.cardreserva{
    float: right;
    margin-right: 85px;
   
}

.total{
    margin: 0px 0px 0px 0px;
    color: var(--color_titulos);
    font-size: 20px;
}

.lineaparque{
    color: var(--color_letras);
}

.lineafacturacion{
    color: var(--color_letras);
    margin-left: 70px;
    margin-right: 500px;
}

.precio{
    color: var(--color_titulos);
    float: right;


}

.parque{
    text-align: left;
    color: var(--color_titulos);
}

.cardcaminata{
    width: 130px;
    float: right;
}

.precio{
    float: right;
}

.facturacion{
    margin-left: 70px;
    color: var(--color_titulos);
}

.formulariocompleto{
    max-width: 1170px;
    margin: 1.5rem;
    padding: 1.5rem;
    display: grid;
    grid-auto-columns: 50%;
    flex-direction: column;  
    margin-left: 55px;  
} 
    /* width: 90%;
    max-width: 400px;
    padding: 3.5em 1.5em;
    margin: 0px;
    
} */
.formulariocompleto p{
    color: var(--color_letras);

}



 @media(max-width:700px){
    .confirmar{
        display: flex;
        flex-wrap: wrap;
    }
} 

@media(max-width:700px){
    .cardreserva{
        display: flex;
        flex-wrap: wrap;
        width: 370px;
        padding:30px ;
    }
} 

@media(max-width:400px){
    .formulariocompleto{
        display: flex;
        flex-wrap: wrap;
        padding: 3.5em 1em;
        display: grid;
        grid-auto-columns: 100%;
    }
} 

@media(max-width:700px){
    .cardcaminata{
        display: flex;
        flex-wrap: wrap;
        width: 80px;
    }
} 

/* @media(min-width:700px){
    .formulariocompleto{
        display: flex;
        flex-wrap: wrap;
        
    }
} */