
/* ----------aventura---------- */
/* .laguna{
    margin-right: 20px;
    margin-bottom: 10px;
    
} */

.imagenfondo1{
    background-repeat: no-repeat;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
}

.imagenfondo1:before {
    content: "";

    background: linear-gradient(90deg, rgb(51, 51, 145));
    opacity: 0.5;
}

.CARDAVEN:hover{
    transform : scale(1.1);
}
.CARDAVEN{

    height: 300px;
    width: 16%;
    margin: 5px;
    border-radius: 30px 0px ;
    margin-right: 15%;
}

.CARDAVENPARQUE:hover{
    transform : scale(1.1);
}
.CARDAVENPARQUE{
    
    height: 300px;
    width: 16%;
    margin: 5px;
    border-radius: 30px 0px ;
    margin-right: 10%;
}




.imgaven__parque{
    
    border-radius: 30px 0px 0px;
    height: 150px;
    width: 100%;
}


.navegacion{
    display: flex;
    flex-wrap: wrap;
}

/* .CARDAVEN__aven{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 1px;
    border-radius: 30px 0px ;
    background-color: white;
    width: 17%;
    height: 90%;
} */

.imgaven{
    
    border-radius: 30px 0px 0px;
    height: 150px;
    width: 100%;
}



.tituloaven p{
    color: rgb(51, 51, 145);
    text-align: left;
    margin-left: auto;
    margin: 15px;
    
}

.fromavenlag{
    color: rgb(51, 51, 145);
    font-size: 18px;
}

.tituloaven a{
    text-decoration: none;
    margin: 0px;
    padding: 0px;
}

.tituloparque p{
    color: rgb(51, 51, 145);
    text-align: left;
    margin-left: auto;
    margin: 5px;
    
}

.tituloparque a{
    text-decoration: none;
}

.fromaven{
    color: rgb(51, 51, 145);
    text-align: left;
    font-size: 18px;
    
}

/* @media (max-width: 700px){
    .imagenfondo{
       display: flex;     
       padding: 0px;
       margin: 0px;
       
    }
  } */

   /* @media (min-width: 400px){
    .CARDAVEN{
        width: 80%;
    }
}  */
   @media (max-width: 700px){
    .CARDAVEN{
        width: 80%;
        height: 350px;
        font-size: 20px;        
        flex-wrap: wrap;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    } 
} 

/* @media (min-width: 700px){
    .CARDAVENPARQUE{
        width: 27%;
    }
} */
@media (max-width: 700px){
    .CARDAVENPARQUE{
        width: 80%;
        height: 350px;
        font-size: 20px;        
        flex-wrap: wrap;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    } 
}


 @media (max-width: 700px){
    .imagenfondo1 {
        display: none;
    }
}

@media (max-width:700px) {
    .laguna{
        display: grid;
    }
}

/* @media (max-width:700px) {
    .CARDAVEN{

    }
} */
/*
@media (max-width: 700px){
    .imagenfondo{
       display: flex;     
       padding: 0px;
       margin: 0px;
    }
  }

  @media (max-width: 700px){
    .CARDAVEN{
        width: 70%;
        height: 250px;
        font-size: 10px;        
        flex-wrap: wrap;
        display: flex;
    } 
}

@media (max-width: 700px){
    .CARDAVEN__2{
        width: 70%;
        height: 250px;
        font-size: 10px;        
        flex-wrap: wrap;
        display: flex;
    } 
} */
