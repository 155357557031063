  .bienvenidos{
    margin-left: 0px;
    /* color: rgb(51, 51, 145);  */
    color: var(--color_titulos);
    display: flex;
    text-align: left;
    
  }
  
  /* .texto__descripcion{
    font-family:'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif
  } */
  @media (max-width: 700px){
    .bienvenidos{
      display: flex;
      margin: 20px;
      padding: 0px;
    }
  }
   @media (max-width: 700px){
  .texto__descripcion div{
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin: 20px;
    
    
  }
} 

/* @media (max-width: 700px){
  .texto__descripcion {
    
    display: flex;
    margin-left: 20px;
    flex-wrap: wrap;
    font-size: 20px;
      margin: 0px;
      padding: 0px;
  }
} */



  .texto__descripcion{
     margin-left: 20px;
    color: var(--color_letras);
  }