.imagenfondo2{
    background-repeat: no-repeat;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
}

.imagenfondo2:before {
    content: "";

    background: linear-gradient(90deg, rgb(51, 51, 145));
    opacity: 0.5;
}
.CARDAVEN_MINI:hover{
    transform : scale(1.1);
}
.CARDAVEN_MINI{
    width: 16%;
    height: 300px;
    margin: 5px;
    border-radius: 30px 0px ;
    margin-right: 15%;
    
}

.titulomini p{
    color: rgb(51, 51, 145);
    text-align: left;
    margin-left: auto;
    margin: 9px;
    display: flex;
    flex-wrap: wrap;
}

.titulomini a{
    text-decoration: none;
}

.CARDAVEN_LAGOS:hover{
    transform : scale(1.1);
}
.CARDAVEN_LAGOS{
    width: 16%;
    height: 300px;
    margin: 5px;
    border-radius: 30px 0px ;
    margin-right: 10%;
    
}

.titulolagos p{
    color: rgb(51, 51, 145);
    text-align: left;
    margin-left: auto;
    margin: 24px;
    
}

.titulolagos a {
    text-decoration: none;
}

.imgalagos{
    border-radius: 30px 0px 0px;
    height: 148px;
    width: 100%;
}

.titulonave p{
    color: rgb(51, 51, 145);
    text-align: left;
    margin-left: auto;
    margin: 0px;
    padding: 0px;
}

.titulonave a{
    text-decoration: none;
}

.fromaven_nave{
    color: rgb(51, 51, 145);
    font-size: 18px;
}

.CARDAVEN_NAVE:hover{
    transform : scale(1.1);
}
.CARDAVEN_NAVE{
    width: 16%;
    height: 300px;
    margin: 5px;
    border-radius: 30px 0px ;
    margin-right: 10%;
   
}
/* .CARDAVEN_MINI{
margin-top: 10px;
margin-bottom: 1px; 
margin-left: auto;
margin-right: auto;
border-radius: 30px 0px ;
background-color: white;
width: 17%;

display: flex;
flex-wrap: wrap;
}

.CARDAVEN_LAGOS{
    margin-top: 10px;
    margin-bottom: 1px; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px 0px ;
    background-color: white;
    width: 17%;
    
display: flex;
flex-wrap: wrap;

}

.CARDAVEN_NAVE{
margin-top: 10px;
margin-bottom: 1px; 
margin-left: auto;
margin-right: auto;
border-radius: 30px 0px ;
background-color: white;
width: 25%;

display: flex;
flex-wrap: wrap;
}

.laguna2{
    margin-left: auto;
    margin-right: auto;
} */

@media (max-width: 700px){
    .CARDAVEN_MINI{
        width: 80%;
        height: 350px;
        font-size: 20px;        
        flex-wrap: wrap;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    } 
}

/* @media (min-width: 699px){
    .CARDAVEN_MINI{
        width: 25%;
        margin-right: 5%;
    }
} */
@media (max-width: 700px){
    .CARDAVEN_LAGOS{
        width: 80%;
        height: 350px;
        font-size: 20px;        
        flex-wrap: wrap;
        display: flex;
        margin-left: 10%;
        margin-top: 35px;
    } 
}

/* @media (min-width: 699px){
    .CARDAVEN_LAGOS{
        width: 25%;
        margin-right: 5%;
    }
} */
@media (max-width: 700px){
    .CARDAVEN_NAVE{
        width: 80%;
        height: 350px;
        font-size: 20px;        
        flex-wrap: wrap;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
    } 
}

/* @media (min-width: 699px){
    .CARDAVEN_NAVE{
        width: 27%;
        margin-right: 5%;
    }
} */
@media (max-width: 700px){
    .imagenfondo2 {
        display: none;
    }
}

@media (max-width:700px) {
    .laguna2{
        display: grid;
        margin-left: auto;
        margin-right: auto;
    }
}