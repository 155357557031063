/* .fl-fl {
    background: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 4px;
        padding-top: 4px;
        padding-right: 4px;
        padding-bottom: 4px;
        padding-left: 4px;
    width: 180px;
    position: fixed;
    right: -5px;
    z-index: 1000;
    font: normal normal 12px 'D-DIN', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-variant-caps: normal;
        font-weight: normal;
        font-stretch: normal;
        font-size: 12px;
        line-height: normal;
        font-family: "D-DIN", Arial, Helvetica, sans-serif;
        font-size-adjust: none;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-variant-alternates: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-variant-position: normal;
        font-language-override: normal;
        font-feature-settings: normal;
        font-variation-settings: normal;
    /* -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease; 
    transition: all .25s ease;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
 */
.btnwhat{
    
    position: fixed;
    display: flex;
}

.btnwhat__img{
    width:100%;
    height:100%;
}



 @media (max-width:1500px) {
.btnwhat{
    right: -30px;
    margin: 15px;
    display: flex;
    flex-direction: column; 
    font-size: 20px;
}} 

/* @media (min-width:600px) {
    .btnwhat{
        display: none;
    }
} */

.btnwhat{
    flex-wrap: wrap;
    display: flex;
    text-align: center;
    margin-left: 1280px;
    margin-right: 35px;
    width: 55px;
    height: 70px;
    bottom: 50px;
    transform:translate(-50%,-50%)  
} 



    .btnwhat a p{color:black; position: fixed;font-family:sans-serif;bottom:0;left:-18px;right:-20px;
    padding:15px 7px;z-index:-1;font-size:14px;border-radius:2px;border-radius:8px;
    background:rgb(243, 189, 12);visibility:hidden;opacity:0;
    -o-transition:all .5s cubic-bezier(0.68,-.55,.265,1.55);
    -webkit-transition:all .5s cubic-bezier(0.68,-.55,.265,1.55);
    -moz-transition:all .5s cubic-bezier(0.68,-.55,.265,1.55);
    transition:all .5s cubic-bezier(0.68,-.55,.265,1.55)}
    a p:before{content:'';width:0;height:0;border-left:5px solid transparent;border-right:5px solid transparent;
    border-top:5px solid #fff;position:absolute;bottom:-5px;left:40px} 
    a:hover p{bottom:90px;visibility:visible;opacity:1}     


    .btnresponsive{
        display: none;
}

/* @media (max-width: 600px) {
    .btnresponsive{
        display: block;
        position: fixed;
        right: 20px;
        bottom: 25px;
        z-index: 1px;
        width: 50px;
        margin: 5px;
     
    }
} */