.textocon h3{
    color: var(--color_titulos);
}

.textocon{
    padding-left: 80px;
    color: var(--color_titulos);
}

.textocon img{
    float:right;
    padding-right: 80px;
    object-fit: cover;
    
}

.lineacon{
    margin-left: 75px;
    margin-right: 695px;
    padding: 5px;
    color:var(--color_letras)
}

.formulario__contacto{
    /* margin-left: 30%;
    margin-right: 700px; */
    display: grid;
    grid-auto-columns: 50%;
    flex-direction: column;  
    
}
 /* @media (min-width: 900px){
    .formulario__contacto{
        display: flex;
        flex-wrap: wrap;
           
    }
}  */

@media(max-width:900px){
    .formulario__contacto{
        padding: 3.5em 1em;
        display: grid;
        grid-auto-columns: 100%;
        width: 200px;
        
        justify-content: center;
       
    }
}

 @media screen and (max-width:700px){
    .formulario__contacto {
        display: flex;
        justify-content: center;
        padding: 3.5em 1em;
        
    }
} 

@media(min-width:700px){
    .nombre{
        display: flex;
        justify-content: center;
        
    }
}

@media(min-width:200px){
    .formulario__contacto{
        display: flex;
        flex-wrap: wrap;
        padding: 3.5em 1em;
        display: grid;
        margin: 40px;
        
    }
}
/* @media(max-width:1000px){
    .formulariocompleto{
        display: flex;
        flex-wrap: wrap;
        padding: 3.5em 1em;
        display: grid;
        grid-auto-columns: 100%;
    }
}  */

@media (max-width: 900px){
    .faro__img{
        display: none;

    }
}

/* @media (max-width: 700px){
    .contacto{
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        margin: 0px;       
    }
} */


 @media (max-width: 750px) {
    .textocon {
        display: flex;
        flex-wrap: wrap;
        
        padding: 0px;
        margin: 10px;

    }
} 

 @media (max-width: 900px){
    .textocon p{
        display: flex;
        flex-wrap: wrap;
        
        
    }
} 
/* .faro{
    float: left;
    
} */

.botonen button{
    width: 50%;
    height: 20%;
    font-size: 15px;
    
}


@media (max-width: 900px){
    .botonen{
        width: 200%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
}

.campos p{
    font-size: 13px;
    color: var(--color_letras);
}